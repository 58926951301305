<template>
  <div>
    <v-row class="main-row">

      <v-col cols="12" md="4" sm="12">
        <v-card class="card">
          <div class="card-title">
            Moji podatki
            <v-btn
                class="card-title-button"
                fab
                x-small
                color="#ff6000"
                @click="console.log('edit button press')"
            >
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            </div>
          <div class="card-content">
            <div style="display: flex; justify-content: center">
              <img :src="profileImagesUrl + memberData.id + '/' +  currentUser.token" style="width: 120px; height: 120px; object-fit: fill; border: solid 1px gray">
            </div>
            <div style="display: flex; justify-content: center">
              <h3 style="margin-top: 10px; margin-bottom: 0">{{ memberData.name }} {{memberData.surname}}</h3>
            </div>
            <div style="display: flex; justify-content: center">
              <p style="font-size: 12px">{{ formatDate(memberData.birthDay) }}</p>
            </div>
            <hr class="mb-2">
            <p style="margin: 0; font-size: 14px"><v-icon small style="margin-right: 5px">mdi-phone</v-icon> {{ memberData.phoneNumber }}</p>
            <p style="margin: 0; font-size: 14px"><v-icon small style="margin-right: 5px">mdi-email</v-icon> {{ memberData.email }}</p>
            <hr class="mt-2 mb-2">
            <p style="margin: 0; font-size: 14px"><v-icon small style="margin-right: 5px">mdi-home</v-icon> {{ memberData.addressLine1 }}</p>
            <p v-if="memberData.addressLine2 != null" style="margin: 0; font-size: 14px"> {{ memberData.addressLine2 }}</p>
            <p style="margin: 0; margin-left: 24px; font-size: 14px"> {{ memberData.ZIP }} {{memberData.postOffice}}</p>
            <hr class="mt-2 mb-2">
            <p style="margin: 0; font-size: 14px">
              <v-icon  style="margin-right: 5px">mdi-certificate</v-icon>
              Orožna listina:
              <v-icon v-if="memberData.hasFirearmsPermit === true" style="margin-left: 5px" color="green">mdi-check-decagram</v-icon>
              <v-icon v-else style="margin-left: 5px" color="red">mdi-cancel</v-icon>
            </p>
            <p v-if="memberData.memberType === 'HONORED_MEMBER'" style="margin: 0; font-size: 14px">
              <v-icon style="margin-right: 5px">mdi-medal</v-icon> Častni član SD PARABELLUM
            </p>
            <p v-if="memberData.memberType === 'FOUNDING_MEMBER'" style="margin: 0; font-size: 14px">
              <v-icon style="margin-right: 5px" color="#bf9b30">mdi-medal</v-icon> Ustanovitveni član SD PARABELLUM
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" sm="12">

        <v-card class="card">
          <div class="card-title">Dosežki</div>
          <div class="card-content">
            <div v-if="memberData.achievements.length > 0">
              <div v-for="achievement in memberData.achievements" :key="achievement.achievementId" class="achievement-div">
                <div style="display: flex;">
                  <v-icon class="mr-3">mdi-podium</v-icon>
                  <h4 class="mr-2 mt-2"> {{ achievement.name }}</h4>
                  <i style="justify-self: right; font-size: 14px; margin-top: 10px" class="ml-2"> - {{ formatDate(achievement.date) }}</i>
                </div>
                <div>
                  <p style="font-size: 14px" class="mt-2 mb-1">{{ achievement.description }}</p>
                </div>
                <v-icon v-if="achievement.place === 1" x-large color="#bf9b30" class="achievement-medal">mdi-medal</v-icon>
                <v-icon v-if="achievement.place === 2" x-large color="#ACACAC" class="achievement-medal">mdi-medal</v-icon>
                <v-icon v-if="achievement.place === 3" x-large color="#663300" class="achievement-medal">mdi-medal</v-icon>
              </div>
            </div>
            <div v-else style="display: flex; justify-content: center;">
              <h4 style="color: #656565; margin-top: 50px; margin-bottom: 50px">Pri SD Parabellum, še nimate dosežkov</h4>
            </div>
          </div>
        </v-card>

        <v-row class="inner-row">
          <v-col cols="12" md="6" sm="12">
            <v-card class="card">
              <div class="card-title">Tekmovanja</div>
              <div class="card-content">
                <div v-if="memberData.contests.length > 0">
                  <div v-for="contest in memberData.contests" :key="contest.contestId" class="contest-div">
                    <h4 class="mr-2 mb-1"><v-icon>mdi-pistol</v-icon> {{ contest.name }}</h4>
                    <p style="font-size: 14px; margin: 0"><v-icon small>mdi-map-marker</v-icon> <b>{{ contest.location }}</b></p>
                    <p style="font-size: 14px; margin: 0"><v-icon small>mdi-calendar</v-icon> <i style="font-size: 14px; margin: 0">{{ formatContestDate(contest.startDate, contest.endDate) }}</i></p>
                    <p style="font-size: 14px"><v-icon small>mdi-note</v-icon> {{ contest.description }}</p>
                  </div>
                </div>
                <div v-else style="display: flex; justify-content: center;">
                  <h4 style="color: #656565; margin-top: 50px; margin-bottom: 50px">Ni udeležitev na tekmovanjih</h4>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card class="card">
              <div class="card-title">
                Moje orožje
                <v-btn
                    class="card-title-button"
                    fab
                    x-small
                    color="#ff6000"
                    @click="console.log('add button press')"
                >
                  <v-icon color="white">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div class="card-content">
                <div v-if="memberData.achievements.length > 0">
                  <div v-for="firearm in memberData.firearms" :key="firearm.contestId" class="contest-div">
                    <v-icon @click="deleteFirearm(firearm.firearmId)" style="float: right">mdi-close</v-icon>
                    <h4 class="mr-2 mb-1">{{ firearm.manufacturer }} - {{ firearm.model }}</h4>
                    <p style="font-size: 14px; margin: 0"><v-icon small>mdi-magazine-pistol</v-icon> Kalber: <b>{{ firearm.caliber }}</b></p>
                    <p v-if="firearm.serialNumber !== null" style="font-size: 14px"><v-icon small>mdi-note</v-icon> SN: {{ firearm.serialNumber }}</p>
                  </div>
                </div>
                <div v-else style="display: flex; justify-content: center;">
                  <h4 style="color: #656565; margin-top: 50px; margin-bottom: 50px">Orožje ni vnešeno</h4>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

      </v-col>

    </v-row>

    <v-expand-transition>
      <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          :height="80"
          :right="true"
          :color="snackbarColor"
          :elevation="24"
      >
        {{ displayMessage }}

      </v-snackbar>
    </v-expand-transition>
  </div>

</template>

<script>
import ApiConnector from "@/services/api-connector.service"
import ApiRequest from "@/models/api-request";
import EndpointsService from "@/services/endpoints.service"
import moment from "moment";

export default {
  name: "member",
  title: "ČLAN - PARABELLUM",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    profileImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'member/image/';
    },
  },
  data() {
    return {

      memberData: {},

      snackbar: false,
      snackbarColor: "#9e1c1c",
      displayMessage: "",
      tab: "",
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    ApiConnector.executeCall(ApiRequest.BuildWithURL("authenticate/check")).then(
        response => {
          if(response.data.isValid !== true){
            localStorage.removeItem('user')
            this.$router.push('/login');
          }
        },
        error => {
          localStorage.removeItem('user')
          this.$router.push('/login');
          console.log("Could not determine session validity. Logging out.")
          console.log(error.response.data.message)
        }
    )

    this.getMemberData();

  },
  methods: {

    getMemberData(){
      ApiConnector.executeCall(ApiRequest.BuildWithURL("member")).then(
          response => {
            this.memberData = response.data;
          },
          error => {
            this.message = "Ni bilo mogoče naložiti podatkov!"
            this.snackbar = true
            console.log(error.response.data.message)
          }
      )
    },

    formatDate(date){
      if (date === null) return "/"
      let d = moment(date);
      return d.format("DD.MM.YYYY")
    },

    formatContestDate(start_date, end_date){
      if (start_date === null) return "";
      let start_moment = moment(start_date);
      if(start_moment.isAfter()) return "Prihajajoče - " + start_moment.format("DD.MM.YYYY");        //isAfter() and isBefore() without arguments defaults to now.
      if(end_date === null) return start_moment.format("DD.MM.YYYY");
      let end_moment = moment(end_date);
      if(end_moment.isAfter()) return "V trajanju: " + start_moment.format("DD.MM.YYYY") + " - " + end_moment.format("DD.MM.YYYY")
      return start_moment.format("DD.MM.YYYY") + " - " + end_moment.format("DD.MM.YYYY")
    }

  }
}
</script>

<style scoped>

  .main-row {
    max-width:1200px;
    padding: 20px;
    margin: auto
  }

  .card {
    margin-bottom: 20px;
  }
  .card-title {
    width: 100%;
    padding: 20px;
    font-size: 19px;
    font-weight: bold;
    border-bottom: solid 1px gray;
    background-color: #000000;
    color: white;
  }
  .card-content {
    width: 100%;
    padding: 20px;
  }

  .card-title-button {
    float: right;
  }

  .achievement-div {
    background-color: #dedede;
    padding: 10px;
    border: solid 1px #ff6000;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .achievement-medal {

  }

  .contest-div {
    background-color: #dedede;
    padding: 10px;
    border: solid 1px #ff6000;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 960px){
    .card {
      margin-bottom: unset;
    }
    .inner-row {
      margin-top: 12px;
    }
  }

  @media only screen and (max-width: 490px){
    .main-row {
      padding: 0;
    }
  }

</style>
